
import Vue, { PropType } from 'vue'
import { ManualCommunication } from '@/types/GCB2'
import MessageTextEditor from '../../../inputs/MessageTextEditor/MessageTextEditor.vue'
import { CONNECTOR_TYPES } from '../../../../vars/general'
import { Connector, Project } from '../../../../types/main'
import messageVarsService from '@root/src/helpers/gcb2/messageVars'
import messages from '@dataheroes/messages'
import { useUser } from '@root/src/hooks/useUser'
import NameBadge from '@root/src/components/inputs/MessageTextEditor/NameBadge.vue'

const varsIds = messages.vars.vars.map(el => el.aliases.map(alias => `{${alias}}`)).flat()
export default Vue.extend({
    components: { MessageTextEditor, NameBadge },
    props: { manualCommunication: { type: Object as PropType<ManualCommunication> } },
    data: () => ({
        tooltip: false,
    }),
    computed: {
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        GCB2Connector(): Connector | undefined {
            return this.project.connectors.find(el => el.connectorType === CONNECTOR_TYPES.GCB2)
        },
        user() {
            const { user } = useUser()
            return user
        },
        varsRegexp() {
            return new RegExp(varsIds.join('|'), 'g')
        },
        processedValue() {
            let result: any[] = []
            const maybeVars: any[] = []
            const splitted: string[] = []
            let curStrIndex = 0

            const matches = this.manualCommunication.textMessage
                ? this.manualCommunication.textMessage.matchAll(this.varsRegexp)
                : []
            const matchesArr = [...matches]

            if (matchesArr.length === 0) {
                result = [{ id: 1, val: this.manualCommunication.textMessage }]
                return result
            }
            for (let match of matchesArr) {
                maybeVars.push({
                    val: match[0],
                    startIndex: match.index,
                    endIndex: Number(match.index) + match[0].length,
                })
            }
            maybeVars.forEach((el, i) => {
                splitted.push(this.manualCommunication.textMessage.substring(curStrIndex, el.startIndex))
                splitted.push(el.val)
                if (i === maybeVars.length - 1) {
                    splitted.push(
                        this.manualCommunication.textMessage.substring(
                            el.endIndex,
                            this.manualCommunication.textMessage.length
                        )
                    )
                }
                curStrIndex = el.endIndex
            })
            result = splitted.map((el, i) =>
                varsIds.includes(el) ? { id: i, varId: el } : { id: i, val: el }
            )
            return result
        },
        showImagePreview() {
            const imageExtensions = ['.png', '.jpg', '.gif', '.jpeg']
            return (
                this.manualCommunication?.activationMedia &&
                imageExtensions.some(ext => this.manualCommunication?.activationMedia?.src?.endsWith(ext))
            )
        },
        showVideoPreview() {
            const videoExtensions = ['.mp4']
            return (
                this.manualCommunication?.activationMedia &&
                videoExtensions.some(ext => this.manualCommunication?.activationMedia?.src?.endsWith(ext))
            )
        },
        showDocPreview() {
            const docExtensions = ['.pdf']
            return (
                this.manualCommunication?.activationMedia &&
                docExtensions.some(ext => this.manualCommunication?.activationMedia?.src?.endsWith(ext))
            )
        },
    },

    methods: {
        copy() {
            navigator.clipboard.writeText(
                messageVarsService.resolveVars(
                    this.manualCommunication.textMessage,
                    this.GCB2Connector,
                    this.manualCommunication
                )
            )
            setTimeout(() => {
                this.tooltip = false
            }, 1000)
        },
        getVarValue(varId: string) {
            const text = messages.substituteVars({
                text: varId,
                data: {
                    communication: this.manualCommunication,
                    gcb2Connector: this.GCB2Connector,
                    project: this.project,
                    userId: this.user.value.sub,
                },
            }).text
            return text
        },
    },
    async created() {},
    mounted() {},
})
